import { useGetBusinessStaffs } from "api";
import { useAuthUser } from "store";

export const usePosPageHook = () => {
  const { userData } = useAuthUser();

  const [staffResult] = useGetBusinessStaffs({
    businessId: userData?.businessId as string,
  });

  const {
    data: staffData,
    fetching: fetchingStaff,
    error: staffError,
  } = staffResult;

  const SELECT_STAFF_OPTIONS = staffError
    ? console.error(staffError)
    : !fetchingStaff &&
      staffData?.getBusinessStaffs?.nodes?.map(
        ({ _id, firstName, lastName }: any) => {
          return { value: _id, label: `${firstName} ${lastName}` };
        }
      );

  return {
    SELECT_STAFF_OPTIONS,
    fetchingStaff,
  };
};
