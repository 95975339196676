import TimerIcon from "assets/icons/timer-icon";
import { VButton, VForm } from "components/common";
import { useAuthUserConstants } from "store";
import SignupWrapper from "../signup/signup-wrapper";
import { StyledResendPasscode } from "../signup/styles";
import { useResetPasscodeContext } from "./context";

export const EnterResetPassscodeOtp = () => {
  const {
    otpInputLists,
    hookForm,
    validateOtp,
    isLoading,
    countdown,
    resendOtp,
  } = useResetPasscodeContext();

  const { user } = useAuthUserConstants();

  const phoneNumber = user?.phoneNumber;

  const formattedTime = `${countdown.minutesLeft}:${countdown.secondsLeft}`;

  return (
    <form onSubmit={validateOtp}>
      <SignupWrapper
        header={"Reset Passcode"}
        subtitle={`A reset code has been sent to ${phoneNumber} `}
        footer={
          <VButton
            text={"Continue"}
            type="submit"
            isLoading={isLoading}
            style={{
              maxWidth: "400px",
              width: "100%",
            }}
          />
        }
      >
        <VForm
          vFormClassName="grid__form"
          inputLists={otpInputLists}
          hookForm={hookForm}
        >
          <StyledResendPasscode>
            <div>
              {countdown.isRunning && (
                <div className="resend">
                  <TimerIcon />
                  <p>Expires in {formattedTime}</p>
                </div>
              )}
            </div>
            {!countdown.isRunning && (
              <div className="resend">
                <p>Didn’t get a code?</p>
                <button onClick={resendOtp} type="button">
                  Resend
                </button>
              </div>
            )}
          </StyledResendPasscode>
        </VForm>
      </SignupWrapper>
    </form>
  );
};
