import { VModal } from "components/common/VModal";
import VButton from "components/common/VButton";
import {
  StyledConfirmButtons,
  //   StyledConfirmtText,
  StyledConfirm,
  StyledPasscode,
} from "components/common/VConfirmModal/styles";
// import { UseMutationState } from 'urql';
import VForm from "../VForm";
import { CUSTOM_VALIDATIONS } from "utils";

export const VPasscodeModal = ({
  title,
  open,
  handleClose,
  hookForm,
  handleSubmit,
}: {
  handleSubmit: () => void;
  title: string;
  open: boolean;
  handleClose: () => void;
  hookForm: any;
  //   customerProduct?: UseMutationState<any, object>;
  //   imageProcessing?: boolean;
  //   confirmText?: string;
  //   cancelText?: string;
  //   submitText?: string;
  //   bgColor?: string;
}) => {
  //   const submitForm = () => {
  //     handleSubmit();
  //   };

  //   const cancelForm = () => {
  //     handleClose();
  //   };

  return (
    <VModal
      title={title}
      open={open}
      close={handleClose}
      width={500}
      alignModalTitle="center"
      modalTitleColor="#000"
      subtitle={"Enter your passcode to continue"}
      alignModalSubTitle="center"
    >
      <StyledPasscode>
        <form>
          <VForm
            inputLists={[
              {
                name: "pin",
                inputType: "otp",
                label: "Enter your passcode",
                required: true,
                numInputs: 4,
                shouldAutoFocus: false,
                validation: {
                  required: "Enter passcode",
                  ...CUSTOM_VALIDATIONS.minLengthCustom(
                    6,
                    "Pin must be 6 numbers"
                  ),
                },
                className: "col__spanfull",
              },
            ]}
            hookForm={hookForm}
          />
          <StyledConfirm>
            <StyledConfirmButtons>
              <VButton
                bgColor="#0077FF"
                textColor="#FFF"
                height="50px"
                text="Submit"
                onClick={handleSubmit}
                // isLoading={imageProcessing || customerProduct?.fetching}
                className="confirm-button"
              />
            </StyledConfirmButtons>
          </StyledConfirm>
        </form>
      </StyledPasscode>
    </VModal>
  );
};
