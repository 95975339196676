import { useForm } from "react-hook-form";
import { VButton, VForm } from "components/common";
import { ReactComponent as PlusCircleIcon } from "assets/icons/plus-circle-icon.svg";
import { ADD_EXPENSE_CATEGORY_INPUT_LIST } from "../constants";
import {
  StyledAddExpenseCategoryForm,
  StyledAddExpenseCategory,
} from "./styles";

const AddExpenseCategory = () => {
  const hookForm = useForm();

  const { handleSubmit } = hookForm;
  const onSubmit = (data: any) => {
    // console.log(data);
  };
  return (
    <StyledAddExpenseCategory>
      <StyledAddExpenseCategoryForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VForm
            inputLists={ADD_EXPENSE_CATEGORY_INPUT_LIST}
            hookForm={hookForm}
          />
          <VButton
            className="float__right"
            icon={<PlusCircleIcon />}
            text="Add Another Category"
            isOutline
          />
          <div className="form--button">
            <VButton text="Save" />
          </div>
        </form>
      </StyledAddExpenseCategoryForm>
    </StyledAddExpenseCategory>
  );
};

export default AddExpenseCategory;
