import useModal from "hooks/useModal";
import { APP_ROUTES } from "routes";
import { useAuthUser } from "store";

export const getTransactionRedirectURL = () => {
  return `${window.location.origin}${APP_ROUTES.VERIFY_PAYMENT}`;
};

// eslint-disable-next-line
export const useBillingCheckoutPage = ({
  hasBilling,
  isRegistration,
}: {
  hasBilling?: boolean;
  isRegistration?: boolean;
}) => {
  const { userData } = useAuthUser();

  const { open, toggleModal } = useModal();
  const {
    open: openSuccessful,
    toggleModal: toggleSuccesful,
    handleOpen,
  } = useModal();

  const handleOpenSuccessful = () => {
    handleOpen();
  };

  return {
    open,
    toggleModal,
    userData,
    openSuccessful,
    toggleSuccesful,
    handleOpenSuccessful,
  };
};
