import { VButton, VModal } from "components/common";

import { VModalProps } from "components/common/types";
import { StyledUpgradePlanModal } from "./styles";

const UpgradePlanModal = ({ open, close }: VModalProps) => {
  return (
    <VModal open={open} close={close} title="Add Store" width={700}>
      <StyledUpgradePlanModal>
        <div>
          <h3>Upgrade Plan</h3>
          <p>
            You’re currently on Basic Plan, you cannot add more than one store.
            Upgrade to Premium to add multiple stores
          </p>
        </div>
        <VButton text="Upgrade Plan" />
      </StyledUpgradePlanModal>
    </VModal>
  );
};

export default UpgradePlanModal;
