import { VModal } from "components/common/VModal";
import VButton from "components/common/VButton";
import {
  StyledConfirmButtons,
  StyledConfirmtText,
  StyledConfirm,
} from "components/common/VConfirmModal/styles";
import { UseMutationState, AnyVariables } from "urql";
import { useAnalytics } from "hooks/useAnalytics";

const VConfirmModal = ({
  name,
  title,
  open,
  handleClose,
  handleSubmit,
  customerProduct,
  imageProcessing,
  confirmText = "Are you sure you want to save the changes you made on",
  cancelText = "No, Cancel",
  submitText = "Yes, Save Changes",
  bgColor = "#0077FF",
}: {
  handleSubmit: () => void;
  name: string;
  title: string;
  open: boolean;
  handleClose: () => void;
  customerProduct?: UseMutationState<any, AnyVariables>;
  imageProcessing?: boolean;
  confirmText?: string;
  cancelText?: string;
  submitText?: string;
  bgColor?: string;
}) => {
  const [logAppEvent, { submitted_edited_product }] = useAnalytics();

  const submitForm = () => {
    handleSubmit();
    if (title === "Confirm Edit") {
      logAppEvent(submitted_edited_product, {}).catch(console.log);
    }
  };

  const cancelForm = () => {
    handleClose();
  };

  return (
    <VModal
      title={title}
      open={open}
      close={handleClose}
      width={700}
      alignModalTitle="center"
    >
      <StyledConfirm>
        <StyledConfirmtText>
          {confirmText} {name || "this inventory"}?
        </StyledConfirmtText>
        <StyledConfirmButtons>
          <VButton
            bgColor="#FFF"
            textColor="#A5B0B7"
            height="50px"
            text={cancelText}
            onClick={cancelForm}
            noborder={false}
            showOutline={true}
            className="confirm-button"
          />
          <VButton
            bgColor={bgColor}
            textColor="#FFF"
            height="50px"
            text={submitText}
            onClick={submitForm}
            isLoading={imageProcessing || customerProduct?.fetching}
            className="confirm-button"
          />
        </StyledConfirmButtons>
      </StyledConfirm>
    </VModal>
  );
};

export default VConfirmModal;
