import MasterCardIcon from "assets/images/svgs/MasterCardIcon";
import { InputListType } from "components/common/types";

export const PAYMENT_INFO_INPUT_LISTS: InputListType[] = [
  {
    name: "card_holder",
    label: "Name on card",
    className: "col__span2",
    id: "cc-name",
  },
  {
    name: "card_exp",
    label: "Expiry",
  },
  {
    name: "card_number",
    label: "CardNumber",
    type: "number",
    id: "cc-number",
    preIcon: <MasterCardIcon height={16} />,
  },
  {
    name: "card_cvc",
    label: "CVV",
    type: "number",
    id: "cc-cvc",
  },
  {
    name: "email",
    label: "Email",
    type: "email",
  },
];
export const BILLING_ADDRESS_INPUT_LISTS: InputListType[] = [
  {
    name: "fullName",
    label: "Full Name",
    className: "col__spanfull",
  },
  {
    name: "address",
    label: "Address 1",
    className: "col__spanfull",
  },
];
