import { CallbackProps } from "hooks/billing/useAlerzoPayBilling";
import { useEffect, useState } from "react";
import { getEnvKey } from "utils";

export const useBillingCardHook = ({
  hasBilling,
}: {
  hasBilling?: boolean;
}) => {
  const [form, setForm] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoaded, scriptLoaded] = useState(false);

  // script loading
  useEffect(() => {
    const script = document.createElement("script");
    script.src = getEnvKey("REACT_APP_VGS_PAYMENT_URL");
    script.async = true;
    script.onload = () => scriptLoaded(true);
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // VGS Collect initialization
  useEffect(() => {
    if (isLoaded) {
      const vgsForm = (window as any)?.VGSCollect.create(
        getEnvKey("REACT_APP_VGS_PAYMENT_TEST_SANDBOX_VAULT"),
        "sandbox",
        (state: any) => {
          // console.log(state);
        }
      );
      setForm(vgsForm);

      vgsForm.field("#cc-holder", {
        type: "text",
        name: "card_holder",
        placeholder: "Enter card holder name",
        validations: ["required"],
        defaultValue: "John Doe",
      });

      vgsForm.field("#cc-number", {
        type: "card-number",
        name: "card_number",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "Enter card number",
        // showCardIcon: true,
        showCardIcon: {
          left: "0px",
        },

        validations: ["required", "validCardNumber"],
        css: {
          boxSizing: "border-box",
          paddingLeft: "45px",
          paddingRight: "0",
        },
        defaultValue: "4111 1111 1111 1111",
      });

      vgsForm.field("#cc-cvc", {
        type: "card-security-code",
        name: "card_cvc",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "CVC",
        validations: ["required", "validCardSecurityCode"],
        defaultValue: "123",
      });

      vgsForm.field("#cc-expiration-date", {
        type: "card-expiration-date",
        name: "card_exp",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "MM / YY",
        validations: ["required", "validCardExpirationDate"],
        defaultValue: "05/25",
      });
      vgsForm.field("#user-email", {
        type: "text",
        name: "user_email",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "Enter your email",
        validations: ["required"],
        defaultValue: "yomionisade@gmail.com",
      });

      if (hasBilling) {
        vgsForm.field("#full-name", {
          type: "text",
          name: "full_name",
          successColor: "#4F8A10",
          errorColor: "#D8000C",
          placeholder: "Enter your full name",
          validations: ["required"],
          defaultValue: "Onisade Abiodun",
        });
        vgsForm.field("#address", {
          type: "text",
          name: "address",
          successColor: "#4F8A10",
          errorColor: "#D8000C",
          placeholder: "Enter your address",
          validations: ["required"],
          defaultValue: "Ikorodu",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  // VGS Collect form submission
  const handleSubmit = (e: any, callback?: CallbackProps) => {
    setIsSubmitting(true);
    e.preventDefault();
    form.submit(
      "/post",
      {},
      (status: any, response: any) => {
        setIsSubmitting(false);
        callback?.({ ...response, status });
      },
      (error: any) => {
        console.error(error);
        setIsSubmitting(false);
      }
    );

    // form.tokenize(
    //   (status: any, response: any) => {
    //     console.log({ status, response });
    //   },
    //   (errors: any) => {
    //     console.log({ errors });
    //   }
    // );
  };

  return {
    form,
    handleSubmit,
    isLoaded,
    isSubmitting,
  };
};
