import { VButton } from "components/common";
import { BillingCheckoutCard } from "./billing-checkout-card";
import { StyledBillingPeriodButton } from "./styles";

export const BillingPeriod = () => {
  return (
    <BillingCheckoutCard title="Billing Period">
      <StyledBillingPeriodButton>
        <VButton
          height="40px"
          text="Monthly"
          bgColor="#DDEAFF"
          textColor="#0077FF"
          width="110px"
        />
        <VButton
          height="40px"
          text="Annually (coming soon)"
          isOutline
          disabled
        />
      </StyledBillingPeriodButton>
    </BillingCheckoutCard>
  );
};
