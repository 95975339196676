import { VForm } from "components/common";
import { useForm } from "react-hook-form";
import { BillingCheckoutCard } from "./billing-checkout-card";
import { BILLING_ADDRESS_INPUT_LISTS } from "./constants";

export type BillingAddressForm = {
  fullName: string;
  address: string;
};

export const BillingAddress = () => {
  const hookForm = useForm<BillingAddressForm>();

  return (
    <BillingCheckoutCard title="Billing Address">
      <VForm
        hookForm={hookForm}
        inputLists={BILLING_ADDRESS_INPUT_LISTS}
        vFormClassName="grid__form"
      />
    </BillingCheckoutCard>
  );
};
