import { BodyContainer, BodyInnerBackground } from "app/App.styled";
import TopNav from "components/top-nav/TopNav";
import { ReactNode } from "react";

import backgroundImage from "../../assets/images/pngs/background-image.png";

// export default useAppContext;

export const SemiAuthenticatedWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <BodyContainer>
      <TopNav />
      <BodyInnerBackground
        style={{
          backgroundImage: `url(${backgroundImage})`,
          height: "100%",
          alignItems: "center",
          paddingTop: "5rem",
        }}
      >
        {children}
      </BodyInnerBackground>
    </BodyContainer>
  );
};
