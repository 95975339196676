import LockIcon from "assets/images/svgs/LockIcon";
import { VLoader } from "components/common";
import { VInputProps } from "components/common/types";
import {
  StyledRequiredDecorator,
  StyledVFormInput,
  StyledVFormLabel,
} from "components/common/VInput/styles";
import { BillingCheckoutCard } from "./billing-checkout-card";
import {
  StyledForm,
  StyledFormField,
  StyledFormFieldGroup,
} from "./styles/card-billing.styles";

export const VPaymentInput = ({ name, label, required, id }: VInputProps) => {
  return (
    <StyledVFormInput type="">
      <StyledVFormLabel htmlFor={name}>
        {label}{" "}
        {required && <StyledRequiredDecorator>*</StyledRequiredDecorator>}
      </StyledVFormLabel>

      <StyledFormField id={name}></StyledFormField>
    </StyledVFormInput>
  );
};

export interface IAddPaymentInfo {
  nameOnCard: string;
  expiry: string;
  cardNumber: string;
  cvv: string;
  email: string;
}

export const PaymentInfo = ({ isLoaded }: { isLoaded: boolean }) => {
  return (
    <BillingCheckoutCard
      title={
        <>
          <p>Payment Info</p>
          <LockIcon height={20} />
        </>
      }
    >
      {!isLoaded ? (
        <VLoader
          style={{
            height: "200px",
          }}
        />
      ) : (
        <StyledForm className="form">
          <StyledFormFieldGroup className="form-field-group">
            <VPaymentInput name="cc-holder" label="Name on card" />
            <VPaymentInput name="cc-expiration-date" label="Expiry" />
          </StyledFormFieldGroup>
          <StyledFormFieldGroup className="form-field-group">
            <VPaymentInput name="cc-number" label="Card number" />
            <VPaymentInput name="cc-cvc" label="CVV" />
          </StyledFormFieldGroup>
          <StyledFormFieldGroup className="form-field-group">
            <VPaymentInput name="user-email" label="Email" />
          </StyledFormFieldGroup>
          {/* <StyledFormButton type="submit" className="form-button">
            Submit
          </StyledFormButton> */}
        </StyledForm>
      )}
    </BillingCheckoutCard>
  );
};
