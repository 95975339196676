import BusinessTypeIcon from "assets/images/svgs/BusinessTypeIcon";
import { VButton } from "components/common";
import { useBusinessRegistrationHook } from "hooks";
import { useAuthUser } from "store";
import { BUSINESS_TYPES } from "./constants";
import {
  StyledChooseBusinessCard,
  StyledChooseBusinessCardWrapper,
  StyledChooseBusinessDesc,
  StyledChooseBusinessHeader,
  StyledChooseBusinessWrapper,
  StyledSignOuterWrapper,
  StyledSignupContent,
  StyledSignupWrapper,
} from "./styles";

export const ChooseBusinessType = (
  customHook: ReturnType<typeof useBusinessRegistrationHook>
) => {
  const {
    steps: { nextStep },
    businessType: { businessType, setBusinessType },
  } = customHook;

  const { userData } = useAuthUser();

  const fullName = `${userData?.lastName ?? ""} ${userData?.firstName ?? ""}`;

  return (
    <StyledSignOuterWrapper>
      <StyledSignupWrapper>
        <StyledSignupContent maxWidth={700}>
          <StyledChooseBusinessWrapper>
            <StyledChooseBusinessHeader>
              <h3>👋🏾 Welcome, let’s get you setup!</h3>
              <p>Personalization - Step 1 of 2</p>
            </StyledChooseBusinessHeader>
            <StyledChooseBusinessDesc>
              <h5>{fullName} what type of business do you run?</h5>
              <p>This is to help us personalise your experience with Veedez.</p>
            </StyledChooseBusinessDesc>
            <StyledChooseBusinessCardWrapper>
              {BUSINESS_TYPES.map(({ title, id, description }) => {
                const isChecked = businessType === id;
                const handleSetBusinessType = () => {
                  setBusinessType(id);
                };

                return (
                  <StyledChooseBusinessCard
                    key={id}
                    isChecked={isChecked}
                    onChange={handleSetBusinessType}
                    htmlFor={id}
                  >
                    <BusinessTypeIcon width={40} height={40} />

                    <div className="card-title">
                      <h5>{title}</h5>
                      <p>{description}</p>
                    </div>
                    <input
                      type="radio"
                      id={id}
                      checked={isChecked}
                      onChange={handleSetBusinessType}
                    />
                  </StyledChooseBusinessCard>
                );
              })}
            </StyledChooseBusinessCardWrapper>
          </StyledChooseBusinessWrapper>
        </StyledSignupContent>
      </StyledSignupWrapper>

      <VButton
        text="Continue"
        style={{
          maxWidth: "400px",
          width: "100%",
        }}
        disabled={!businessType}
        onClick={nextStep}
      />
    </StyledSignOuterWrapper>
  );
};
