import { VBodyLayoutProps } from "../types";
import { VEmptyState } from "../VEmptyState";

export const VBodyLayout = ({
  fetching,
  hasError,
  errorText = "Error getting data",
  children,
}: VBodyLayoutProps) => {
  if (fetching) {
    return <h3>Loading....</h3>;
  }

  if (hasError) {
    return <VEmptyState title={errorText} />;
  }

  return <div>{children}</div>;
};
