import { useOauthIframeHook } from "hooks";

const OauthIFramePage = () => {
  const { iframeRef, oauthData } = useOauthIframeHook();
  const { url } = oauthData;

  return (
    <div>
      <div>
        <p>{url}</p>
      </div>
      <iframe
        title="Veedex Login"
        ref={iframeRef}
        src={url}
        width="100%"
        height="800"
        // className={styles.window}
      />
    </div>
  );
};

export default OauthIFramePage;
